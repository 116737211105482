


































































import TitleBar from "@/components/TitleBar/index.vue";
import Vue from "vue";
import ProgramsHeader from "@/components/ProgramsHeader/index.vue";
import RegisterAttendeeForm from "@/components/RegisterAttendeeForm/index.vue";
import { ProgramsManager } from "@/services/ProgramsManager";
import Program from "@/Classes/Program";
import SessionSelect from "@/components/SessionSelect/index.vue";
import BackButton from "@/components/BackButton/index.vue";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";

export default Vue.extend({
  name: "AppProgramsAttendanceRegisterController",
  components: {
    BackButton,
    TitleBar,
    ProgramsHeader,
    RegisterAttendeeForm,
    SessionSelect,
  },
  data() {
    return {
      programName: "" as string | null,
      loading: false as boolean,
      sessions: [] as string[],
      program: {} as Program,
      errors: [] as string[],
      registerSuccessText: "" as string,
    };
  },
  computed: {
    breadcrumbs(): any[] {
      return [
        {
          text: "Programs",
          disabled: true,
        },
        {
          text: this.programName,
          disabled: true,
        },
        {
          text: "Attendance",
          disabled: true,
        },
      ];
    },
    bannerImage(): string | null {
      return this.program?.attributes?.bannerImageUrl;
    },
    sessionId(): string {
      return this.$route.params.sessionId;
    },
    programId(): string {
      return this.$route.params.programId;
    },
    registrationType(): string {
      return this.$route.params.registrationType;
    },
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      const programsManager = new ProgramsManager();
      programsManager
        .getProgram(this.programId)
        .then((program) => {
          this.program = program;
          this.programName = program.attributes.title;
        })
        .catch((response) => {
          this.loading = false;
          this.errors = ApiResponseHelper.getErrorMessagesFromResponse(
            response
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onEnrolmentAdded() {
      this.registerSuccessText = "Enrolment added successfully";
    },
    onInvitationSent() {
      this.registerSuccessText = "Invitation sent successfully";
    },
    onGuestRegistered(): void {
      this.registerSuccessText = "Guest registered successfully";
    },
  },
});
